import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { memo } from 'react'

import { useGetThemesQuery } from 'modules/api'
import { ThemeListSkeleton } from 'modules/theming/components/ThemeListSkeleton'

import { Theme } from '../types'
import { themeSortFunction } from '../utils/utils'
import { ThemePreview } from './ThemePreview'

type CustomizeThemePanelComponentProps = {
  theme: Theme
  onThemeClick: (theme: Theme) => void
  onSelectClick: () => void
}

export const CustomizeThemePanelComponent = ({
  theme,
  onThemeClick,
  onSelectClick,
}: CustomizeThemePanelComponentProps) => {
  const { data: globalData, loading: loadingGlobalThemes } = useGetThemesQuery({
    variables: {
      // @ts-ignore
      workspaceId: null,
      archived: false,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })
  const globalThemes = globalData?.themes || []

  return (
    <>
      {loadingGlobalThemes ? (
        <ThemeListSkeleton size={4} />
      ) : (
        <Box flex={1} p={6}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={1}>
            {[...globalThemes].sort(themeSortFunction).map((t) => {
              return (
                <ThemePreview
                  key={t.id}
                  type="standard"
                  theme={t}
                  isChecked={t.id === theme.id}
                  onThemeClicked={onThemeClick}
                  variant="ghost"
                />
              )
            })}
          </SimpleGrid>
        </Box>
      )}

      <Flex
        pos="sticky"
        bottom={0}
        px={6}
        py={4}
        alignItems="center"
        bg="white"
      >
        <Button
          data-testid="select-this-theme"
          flex={1}
          variant="solid"
          leftIcon={<FontAwesomeIcon icon={regular('sparkles')} />}
          onClick={onSelectClick}
        >
          <Trans>Start with this theme</Trans>
        </Button>
      </Flex>
    </>
  )
}

export const CustomizeThemePanel = memo(CustomizeThemePanelComponent)
