import { FormControl } from '@chakra-ui/react'

import { LogoPicker } from '../LogoPicker'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigLogoPanel = () => {
  const { pop, state, dispatch, label } = useThemeConfigNavigationContext()
  const { theme } = state
  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <FormControl>
        <LogoPicker
          value={theme.logoUrl || null}
          onLogoUploadStart={() => {
            dispatch({
              type: 'THEME_LOGO_UPLOAD_STARTED',
            })
          }}
          updateValue={(src) =>
            dispatch({
              type: 'THEME_LOGO_UPLOAD_SUCCESS',
              data: { logoUrl: src ?? undefined },
            })
          }
          onLogoUploadFail={() => {
            dispatch({
              type: 'THEME_LOGO_UPLOAD_FAIL',
            })
          }}
        />
      </FormControl>
    </ThemeConfigNavigationWrapper>
  )
}
