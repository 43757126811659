import {
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { useFeatureFlag } from 'modules/featureFlags'
import { findClosestFontWeight } from 'modules/fonts/utils/utils'
import {
  DEFAULT_BODY_COLOR,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_FONTS,
  DEFAULT_FONT_WEIGHTS,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
} from 'modules/theming/constants'
import {
  getFontWeightOptions,
  getUpdatedThemeFonts,
} from 'modules/theming/utils/fonts'
import { isThemeDark } from 'modules/theming/utils/utils'
import { generateFontsUrl } from 'utils/url'

import { ColorOrGradientPicker } from '../ColorOrGradientPicker'
import { FontPicker } from '../FontPicker'
import { FontWeightPicker } from '../FontWeightPicker'
import { LinearGradient } from '../LinearGradientPicker/types'
import { TextColorFormControl } from '../TextColorFormControl'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigFontPanel = () => {
  const customFontsEnabled = useFeatureFlag('customFonts')
  const { label, dispatch, state, pop } = useThemeConfigNavigationContext()
  const { theme, fontState } = state
  const { fonts, fontsMap } = fontState

  const updateHeadingFontAndWeight = useCallback(
    (fontId: string) => {
      const maybeHeadingFont = fontsMap[fontId]
      const fontWeights = getFontWeightOptions(maybeHeadingFont)
      const nextFontWeight =
        findClosestFontWeight(
          fontWeights,
          theme.headingFontWeight || DEFAULT_FONT_WEIGHTS.heading
        ) || DEFAULT_FONT_WEIGHTS.heading
      const nextThemeFonts = getUpdatedThemeFonts({
        fontsMap,
        headingFontId: fontId,
        bodyFontId: theme.bodyFont,
      })
      dispatch({
        type: 'THEME_UPDATE_THEME_PROPS',
        data: {
          themeProps: {
            headingFont: fontId,
            headingFontWeight: nextFontWeight,
            fonts: nextThemeFonts,
          },
        },
      })
    },
    [dispatch, fontsMap, theme.bodyFont, theme.headingFontWeight]
  )

  const updateBodyFontAndWeight = useCallback(
    (fontId: string) => {
      const maybeBodyFont = fontsMap[fontId]
      const fontWeights = getFontWeightOptions(maybeBodyFont)
      const nextFontWeight =
        findClosestFontWeight(
          fontWeights,
          theme.bodyFontWeight || DEFAULT_FONT_WEIGHTS.body
        ) || DEFAULT_FONT_WEIGHTS.body
      const nextThemeFonts = getUpdatedThemeFonts({
        fontsMap,
        bodyFontId: fontId,
        headingFontId: theme.headingFont,
      })
      dispatch({
        type: 'THEME_UPDATE_THEME_PROPS',
        data: {
          themeProps: {
            bodyFont: fontId,
            bodyFontWeight: nextFontWeight,
            fonts: nextThemeFonts,
          },
        },
      })
    },
    [dispatch, fontsMap, theme.headingFont, theme.bodyFontWeight]
  )

  const updateHeadingWeight = useCallback(
    (weight: number) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_PROPS',
        data: { themeProps: { headingFontWeight: weight } },
      }),
    [dispatch]
  )

  const updateBodyWeight = useCallback(
    (weight: number) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_PROPS',
        data: { themeProps: { bodyFontWeight: weight } },
      }),
    [dispatch]
  )

  const updateHeadingColor = useCallback(
    (color: string | null) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_CONFIG',
        data: { themeConfig: { headingColor: color ?? undefined } },
      }),
    [dispatch]
  )

  const updateHeadingGradient = useCallback(
    (gradient: LinearGradient | null) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_CONFIG',
        data: { themeConfig: { headingGradient: gradient ?? undefined } },
      }),
    [dispatch]
  )

  const updateBodyColor = useCallback(
    (color: string | null) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_CONFIG',
        data: { themeConfig: { bodyColor: color ?? undefined } },
      }),
    [dispatch]
  )

  const handleFontUploadClick = useCallback(() => {
    const fontUrl = generateFontsUrl()
    window.open(fontUrl, '_blank')
  }, [])

  const headingFont =
    fontsMap[theme.headingFont || DEFAULT_FONTS.headingFont] ||
    fontsMap[DEFAULT_FONTS.headingFont]
  const bodyFont =
    fontsMap[theme.bodyFont || DEFAULT_FONTS.bodyFont] ||
    fontsMap[DEFAULT_FONTS.bodyFont]
  const headingWeight = theme.headingFontWeight || DEFAULT_FONT_WEIGHTS.heading
  const bodyWeight = theme.bodyFontWeight || DEFAULT_FONT_WEIGHTS.body
  const isDark = isThemeDark(theme)

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <Stack>
        <FormControl mb="2">
          <FormLabel>
            <Text>
              <Trans>Heading font</Trans>
            </Text>
          </FormLabel>
          <FontPicker
            fonts={fonts}
            value={theme.headingFont}
            customFontsEnabled={customFontsEnabled}
            updateValue={updateHeadingFontAndWeight}
            onFontUploadClick={handleFontUploadClick}
            weight="bold"
            defaultFont={DEFAULT_FONTS.headingFont}
            data-testid="custom-theme-heading-font-picker"
          />
          {customFontsEnabled && (
            <FontWeightPicker
              font={headingFont}
              value={headingWeight}
              defaultWeight={700}
              updateValue={updateHeadingWeight}
            />
          )}
        </FormControl>
        {/* HEADING COLOR */}
        <FormControl mb="2" data-testid="custom-theme-heading-color-picker">
          <FormLabel>
            <Text>
              <Trans>Heading color</Trans>
            </Text>
          </FormLabel>
          <ColorOrGradientPicker
            color={theme.config.headingColor}
            updateColor={updateHeadingColor}
            defaultColor={
              isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR
            }
            gradient={theme.config.headingGradient}
            updateGradient={updateHeadingGradient}
          />
        </FormControl>
        {/* BODY FONT */}
        <FormControl>
          <FormLabel>
            <Text>
              <Trans>Body font</Trans>
            </Text>
          </FormLabel>
          <FontPicker
            fonts={fonts}
            value={theme.bodyFont}
            customFontsEnabled={customFontsEnabled}
            updateValue={updateBodyFontAndWeight}
            onFontUploadClick={handleFontUploadClick}
            weight="normal"
            defaultFont={DEFAULT_FONTS.bodyFont}
            data-testid="custom-theme-body-font-picker"
          />
          {customFontsEnabled && (
            <FontWeightPicker
              font={bodyFont}
              value={bodyWeight}
              defaultWeight={400}
              updateValue={updateBodyWeight}
            />
          )}
        </FormControl>
        {/* BODY COLOR */}
        <TextColorFormControl
          label={t`Body color`}
          value={theme.config?.bodyColor || null}
          defaultValue={isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR}
          updateValue={updateBodyColor}
        />
        <Alert fontSize="xs" mt="6">
          <AlertIcon />
          <Text>
            <Trans>
              Note: these colors may be lightened or darkened for contrast and
              accessibility.
            </Trans>
          </Text>
        </Alert>
      </Stack>
    </ThemeConfigNavigationWrapper>
  )
}
