import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'

import { ThemeDispatch } from 'modules/theming/themeReducer/types'

import { getThemeBase, THEME_BASES } from '../themeBases'
import { Theme, ThemeBase } from '../types'

export type ThemeConfigFormControlProps = {
  dispatch: ThemeDispatch
  theme: Theme
}
export const ThemeBaseFormControl = ({
  theme,
  dispatch,
}: ThemeConfigFormControlProps) => {
  const currentBase = getThemeBase(theme)

  return (
    <FormControl>
      <FormLabel>
        <Trans>Card style</Trans>
      </FormLabel>
      <Menu matchWidth>
        <MenuButton
          size="lg"
          as={Button}
          borderRadius="md"
          rightIcon={
            <FontAwesomeIcon
              icon={regular('chevron-down')}
              transform="shrink-6"
            />
          }
          variant="toolbar"
          px={4}
          h={10}
          w="100%"
          border="1px solid"
          borderColor="gray.200"
        >
          <Text textAlign="left" fontWeight="400">
            {currentBase.name()}
          </Text>
        </MenuButton>
        <MenuList maxH="320px" maxW="100%" overflow="auto">
          {THEME_BASES.map((base) => {
            return (
              <MenuItemOption
                isChecked={currentBase.key === base.key}
                key={base.key}
                value={base.key}
                onClick={() =>
                  dispatch({
                    type: 'THEME_UPDATE_THEME_CONFIG',
                    data: { themeConfig: { themeBase: base.key } },
                  })
                }
              >
                <HStack maxW="100%">
                  <ThemeBasePreview base={base} />
                  <Box flex="1" minW="0">
                    {base.name()}
                    {base.description && (
                      <Text color="gray.400" fontSize="sm">
                        {base.description()}
                      </Text>
                    )}
                  </Box>
                </HStack>
              </MenuItemOption>
            )
          })}
        </MenuList>
      </Menu>
    </FormControl>
  )
}

const ThemeBasePreview = ({ base }: { base: ThemeBase }) => {
  const cssVars = {
    '--card-color': '#FFF',
    ...base.getCSSVars({
      cardColor: '#FFF',
      accentColor: '#F00',
      accentPalette: ['#F00'],
    }),
  }
  return (
    <Box backgroundColor="gray.200" p={4} css={cssVars}>
      <Box sx={base.cardSx} h="3em" w="5em"></Box>
    </Box>
  )
}
