import {
  Center,
  Flex,
  HStack,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Plural, Trans } from '@lingui/macro'
import { Editor, JSONContent } from '@tiptap/core'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Doc,
  ExistingWorkspace,
  GetDocQuery,
  useGetThemeQuery,
} from 'modules/api'
import { selectCards } from 'modules/cards/reducer'
import {
  GAMMA_ARTIFACT_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
} from 'modules/i18n/properNouns'
import { useAppSelector } from 'modules/redux'
import { EditorStatic } from 'modules/tiptap_editor'
import { GlobalCardStyles } from 'modules/tiptap_editor/extensions/Card/GlobalCardStyles'
import { DEFAULT_DOC_BACKGROUND } from 'modules/tiptap_editor/styles/constants'
import { useGammaBreakpointValue } from 'utils/breakpoints/useGammaBreakpointValue'
import { useDebounced } from 'utils/hooks'
import { docTitleOrPlaceholder } from 'utils/nouns'

import { getEmptyThemeName } from '../constants'
import { useGetFonts } from '../hooks'
import { ThemeDispatch, ThemeState } from '../themeReducer/types'
import { getThemeTesterDoc } from '../themeTesterDoc'
import { Theme } from '../types'
import { makeCleanCustomTheme } from '../utils/utils'
import { CustomizeThemePanel } from './CustomizeThemePanel'
import { FontLoader } from './FontLoader'
import { ThemeConfigNavigationPanel } from './ThemeConfig/ThemeConfigNavigationPanel'
import { ThemeNameEditableInput } from './ThemeNameEditableInput'

const THEME_VIEWER_CLASS = 'theme-editor-root'

interface ThemeEditorProps {
  state: ThemeState
  dispatch: ThemeDispatch
  docContent?: JSONContent
  doc?: Doc
  workspaceId?: ExistingWorkspace['id']
}

export const ThemeEditor = ({
  state,
  dispatch,
  docContent,
  doc,
  workspaceId,
}: ThemeEditorProps) => {
  const { theme, customizationStep } = state
  // A live preview of the state. Debounced to avoid constant updates while typing
  const [themePreview, setThemePreview] = useState<Theme>()
  const [testerEditorInstance, setTesterEditorInstance] =
    useState<Editor | null>(null)
  const setThemePreviewDebounced = useDebounced(setThemePreview, 300)
  useEffect(() => {
    setThemePreviewDebounced(theme)
  }, [theme, setThemePreviewDebounced])

  const { globalFonts, workspaceFonts, fontsMap, allThemeFonts } =
    useGetFonts(workspaceId)

  // Force the doc to use the theme's background and not its own bg,
  // so that you can see what you're changing
  if (docContent?.content && docContent.content[0].attrs?.background) {
    docContent.content[0].attrs.background = DEFAULT_DOC_BACKGROUND
  }
  const cardData = useAppSelector(selectCards)
  const configPanelWidth =
    useGammaBreakpointValue({
      base: 'var(--chakra-sizes-xs)',
      lg: 'var(--chakra-sizes-md)',
      '2xl': 'var(--chakra-sizes-xl)',
    }) || 'var(--chakra-sizes-md)'

  const testerDoc = useMemo(() => getThemeTesterDoc(), [])
  useEffect(() => {
    const accentBackgrounds = themePreview?.config.accentBackgrounds
    if (!accentBackgrounds) {
      // Don't do anything if there have never been accent backgrounds for the theme
      return
    }
    if (testerEditorInstance && accentBackgrounds.length === 0) {
      // reset back to original exampleDoc if there are no more accent backgrounds
      testerEditorInstance.commands.setContent(testerDoc)
      return
    }

    testerEditorInstance?.commands.updateThemeAccentImages(
      accentBackgrounds,
      true
    )
  }, [testerEditorInstance, themePreview?.config.accentBackgrounds, testerDoc])

  const onCustomizeThemeClick = useCallback(
    (t: Theme) => {
      dispatch({
        type: 'SET_NEW_THEME_FOR_SELECTING',
        data: {
          theme: makeCleanCustomTheme(t),
        },
      })
    },
    [dispatch]
  )

  const onCustomizeSelectClick = useCallback(() => {
    dispatch({
      type: 'SET_NEW_THEME_FOR_CUSTOMIZING',
      data: {
        theme: makeCleanCustomTheme(theme, true),
      },
    })
  }, [dispatch, theme])

  return (
    <Flex direction="row-reverse" h="100%">
      {/* Sidebar with theme config */}
      <Flex
        direction="column"
        overflowY="auto"
        w={configPanelWidth}
        borderLeft="1px solid"
        borderColor="gray.200"
      >
        <Flex flex="1" direction="column" position="relative">
          {customizationStep === 'selection' ? (
            <CustomizeThemePanel
              theme={theme}
              onThemeClick={onCustomizeThemeClick}
              onSelectClick={onCustomizeSelectClick}
            />
          ) : (
            <ThemeConfigNavigationPanel
              theme={theme}
              dispatch={dispatch}
              fonts={{ globalFonts, workspaceFonts }}
              fontsMap={fontsMap}
            />
          )}
        </Flex>
      </Flex>

      {/* Main doc viewer */}
      <Flex
        flex={1}
        bg="gray.100"
        position="relative"
        maxW={`calc(100vw - ${configPanelWidth})`}
      >
        <Flex
          className={THEME_VIEWER_CLASS}
          overflowY="scroll"
          width="100%"
          height="100%"
          direction="column"
          position="relative"
          css={{ '--editor-width': '100%' }}
        >
          <GlobalCardStyles />
          <FontLoader fonts={allThemeFonts} />
          <Tabs
            variant="soft-rounded"
            size="sm"
            data-testid="custom-theme-tabs"
          >
            {docContent && ( // Only show the tabs if both of them will show
              <Center position="sticky" top={0} zIndex={2} background="white">
                <TabList
                  p={2}
                  position="absolute"
                  top="1rem"
                  background="white"
                  borderRadius="full"
                  shadow="md"
                >
                  <Tab data-testid="custom-theme-tester-deck-tab">
                    <HStack>
                      <FontAwesomeIcon icon={solid('eye')} />
                      <Text>
                        <Trans>Tester page</Trans>
                      </Text>
                    </HStack>
                  </Tab>

                  <Tab data-testid="custom-theme-current-deck-tab">
                    <HStack>
                      <FontAwesomeIcon icon={regular('rectangle-history')} />
                      <Text>
                        <Trans>Current page</Trans>
                      </Text>
                    </HStack>
                  </Tab>
                </TabList>
              </Center>
            )}
            <TabPanels data-testid="custom-theme-tab-panels">
              <TabPanel p={0} data-testid="custom-theme-preview-editor">
                <EditorStatic
                  onCreate={({ editor: staticEditor }) =>
                    setTesterEditorInstance(staticEditor)
                  }
                  initialContent={testerDoc}
                  theme={themePreview}
                  scrollingParentSelector={`.${THEME_VIEWER_CLASS}`}
                  isInsideDrawer={true}
                />
              </TabPanel>
              {docContent && (
                <TabPanel p={0} data-testid="custom-theme-preview-editor">
                  <EditorStatic
                    reduxData={{ cards: cardData }}
                    initialContent={docContent}
                    theme={themePreview}
                    scrollingParentSelector={`.${THEME_VIEWER_CLASS}`}
                    doc={doc}
                    isInsideDrawer={true}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </Flex>
  )
}

interface ThemeEditorHeaderProps {
  name?: string
  doc?: GetDocQuery['doc']
  theme?: Theme
  themeValidationError: string | null
  dispatch: ThemeDispatch
}

export const ThemeEditorHeader = ({
  name,
  doc,
  theme,
  themeValidationError,
  dispatch,
}: ThemeEditorHeaderProps) => {
  const [fetchedTheme, setFetchedTheme] = useState<Theme>()

  // Refetch here because theme.docCount is expensive to calculate so we only
  // get it when you edit a theme
  const { data, loading } = useGetThemeQuery({
    variables: {
      id: theme?.id,
    },
    skip: !theme || theme.id === 'new',
  })
  const docTitle = docTitleOrPlaceholder(doc?.title)

  useEffect(() => {
    if (loading || !data) return
    if (data.theme) {
      setFetchedTheme(data.theme)
    }
  }, [loading, data, setFetchedTheme])

  const handleThemeNameSave = useCallback(
    (val?: string) => {
      const newName = val ? val : getEmptyThemeName()
      dispatch({
        type: 'THEME_UPDATE_THEME_NAME',
        data: { name: newName },
      })
    },
    [dispatch]
  )

  return (
    <>
      <ThemeNameEditableInput
        value={name || getEmptyThemeName()}
        onSave={handleThemeNameSave}
        themeValidationError={themeValidationError}
      />
      {loading ? (
        <SkeletonText noOfLines={1} fontSize="sm" maxW={'300px'} />
      ) : (
        <Text
          color="gray.700"
          fontSize="sm"
          fontWeight="normal"
          letterSpacing="normal"
        >
          {fetchedTheme?.createdBy && (
            <Trans>Created by {fetchedTheme.createdBy.displayName}.</Trans>
          )}{' '}
          {fetchedTheme?.docCount &&
          fetchedTheme?.docCount === 1 &&
          fetchedTheme?.id === doc?.theme?.id ? (
            <Trans>
              <strong>{docTitle}</strong> is the only{' '}
              {GAMMA_ARTIFACT_PROPER_NOUN} using this theme.
            </Trans>
          ) : (
            <>
              <Plural
                value={fetchedTheme?.docCount || 0}
                one={
                  <Trans>
                    <strong># {GAMMA_ARTIFACT_PROPER_NOUN}</strong> is using
                    this theme.
                  </Trans>
                }
                other={
                  <Trans>
                    <strong># {GAMMA_ARTIFACT_PROPER_NOUN_PLURAL}</strong> are
                    using this theme.
                  </Trans>
                }
              />
            </>
          )}
        </Text>
      )}
    </>
  )
}
