import { Box, Button, Image, VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useState } from 'react'

import { UploadBox } from 'modules/media'
import { UploadStatus } from 'modules/media/types/ImageUpload'
import { useAppSelector } from 'modules/redux'
import {
  MediaPlaceholderErrorUploadingTag,
  MediaPlaceholderImage,
  MediaPlaceholderSpinner,
} from 'modules/tiptap_editor/extensions/media/Placeholder/MediaPlaceholderImage'
import { selectDocOrgId } from 'modules/tiptap_editor/reducer'
import { useUserContext } from 'modules/user'
import { preventDefaultToAvoidBlur } from 'utils/handlers'
import { isHEICFileType } from 'utils/image'

type LogoPickerProps = {
  value: string | null
  updateValue: (src: string | null) => void
  onLogoUploadStart?: () => void
  onLogoUploadFail?: () => void
}
export const LogoPicker = ({
  value,
  updateValue,
  onLogoUploadStart,
  onLogoUploadFail,
}: LogoPickerProps) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus | null>(null)
  const [tempUrl, setTempUrl] = useState<string | null>(null)
  const docOrgId = useAppSelector(selectDocOrgId)
  const { currentWorkspace } = useUserContext()
  // prefer using doc org id when available
  const orgId = docOrgId || currentWorkspace?.id

  if (!orgId) return null

  return value || tempUrl ? (
    <VStack align="flex-start" spacing={4}>
      <Box position="relative" w="100%" background="gray.50">
        {tempUrl == 'placeholder' ? (
          <MediaPlaceholderImage w="100%" />
        ) : (
          <Image
            w="100%"
            src={value || tempUrl || undefined}
            alt=""
            // Prevents dragging the logo
            onMouseDown={preventDefaultToAvoidBlur}
          />
        )}
        {/* Upload status indicators */}
        {uploadStatus == UploadStatus.Uploading && <MediaPlaceholderSpinner />}
        {uploadStatus == UploadStatus.Error && (
          <MediaPlaceholderErrorUploadingTag />
        )}
      </Box>

      <Button
        onClick={() => {
          updateValue(null)
          setUploadStatus(null)
        }}
        mt={2}
        w="100%"
      >
        <Trans>Remove Logo</Trans>
      </Button>
    </VStack>
  ) : (
    <UploadBox
      orgId={orgId}
      uploadType="image"
      editType={'icon'}
      dropHereText={t`Drag your own logo or ${`%{browse}`}`}
      customHeight="250px"
      customFontSize="sm"
      showTip={false}
      onUploadStart={({ file, tempUrl: blobUrl }) => {
        setUploadStatus(UploadStatus.Uploading)
        if (onLogoUploadStart) {
          onLogoUploadStart()
        }
        if (file.type && isHEICFileType(file.type)) {
          setTempUrl('placeholder')
        } else {
          setTempUrl(blobUrl)
        }
      }}
      onUploadSuccess={({ src }) => {
        setUploadStatus(UploadStatus.Done)
        updateValue(src)
        setTempUrl(null)
      }}
      onUploadFailed={(_message) => {
        setUploadStatus(UploadStatus.Error)
        if (onLogoUploadFail) {
          onLogoUploadFail()
        }
      }}
    />
  )
}
