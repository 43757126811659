import cloneDeep from 'lodash/cloneDeep'
import React from 'react'

import { EMPTY_THEME } from '../constants'
import {
  ThemeAction,
  ThemeDispatch,
  ThemeReducerState,
  ThemeState,
} from './types'

export const initialThemeState: ThemeState = {
  theme: EMPTY_THEME,
  themeValidationError: null,
  status: 'new',
  themeBackgroundImgStatus: 'idle',
  themeLogoImgStatus: 'idle',
  customizationStep: null,
  originalTheme: null,
}

function themeReducer(state: ThemeState, action: ThemeAction): ThemeState {
  switch (action.type) {
    case 'NEW_BLANK_THEME': {
      return {
        ...cloneDeep(initialThemeState),
        customizationStep: 'customization',
      }
    }
    case 'THEME_RESET': {
      return cloneDeep(initialThemeState)
    }
    case 'SET_THEME_EDITING': {
      // editing an existing custom theme
      const { theme } = action.data
      const originalTheme = cloneDeep(theme)
      return {
        ...state,
        status: 'editing',
        theme,
        originalTheme,
        customizationStep: 'customization',
      }
    }
    case 'SET_NEW_THEME_FOR_SELECTING': {
      // Setting a new theme for selecting
      const { theme } = action.data

      return {
        ...state,
        status: 'new',
        theme,
        customizationStep: 'selection',
      }
    }
    case 'SET_NEW_THEME_FOR_CUSTOMIZING': {
      // customizing a new theme
      const { theme } = action.data
      const originalTheme = cloneDeep(theme)
      return {
        ...state,
        theme,
        originalTheme,
        customizationStep: 'customization',
      }
    }
    case 'THEME_UPDATE_THEME_PROPS': {
      const { theme } = state
      const { themeProps } = action.data
      return {
        ...state,
        theme: { ...theme, ...themeProps },
      }
    }
    case 'THEME_UPDATE_THEME_CONFIG': {
      const { theme } = state
      const { themeConfig } = action.data
      return {
        ...state,
        theme: {
          ...theme,
          config: {
            ...theme.config,
            ...themeConfig,
          },
        },
      }
    }
    case 'THEME_BACKGROUND_IMAGE_UPLOAD_STARTED': {
      const { theme } = state
      const { background } = action.data
      return {
        ...state,
        themeBackgroundImgStatus: 'loading',
        theme: {
          ...theme,
          config: {
            ...theme.config,
            background,
          },
        },
      }
    }
    case 'THEME_BACKGROUND_UPDATE_SUCCESS': {
      const { theme } = state
      const { background } = action.data
      return {
        ...state,
        themeBackgroundImgStatus: 'idle',
        theme: {
          ...theme,
          config: {
            ...theme.config,
            background,
          },
        },
      }
    }
    case 'THEME_BACKGROUND_IMAGE_UPLOAD_FAIL': {
      return {
        ...state,
        themeBackgroundImgStatus: 'idle',
      }
    }
    case 'THEME_LOGO_UPLOAD_STARTED': {
      return {
        ...state,
        themeLogoImgStatus: 'loading',
      }
    }
    case 'THEME_LOGO_UPLOAD_SUCCESS': {
      const { theme } = state
      const { logoUrl } = action.data
      return {
        ...state,
        themeLogoImgStatus: 'idle',
        theme: {
          ...theme,
          logoUrl,
        },
      }
    }
    case 'THEME_LOGO_UPLOAD_FAIL': {
      return {
        ...state,
        themeLogoImgStatus: 'idle',
      }
    }
    case 'THEME_UPDATE_THEME_NAME': {
      const { theme } = state
      const { name } = action.data
      return {
        ...state,
        theme: {
          ...theme,
          name,
        },
        themeValidationError: null,
      }
    }
    case 'THEME_VALIDATION_ERROR': {
      const { errorMessage } = action.data
      return {
        ...state,
        themeValidationError: errorMessage,
      }
    }
    case 'THEME_VALIDATION_ERROR_RESET': {
      return {
        ...state,
        themeValidationError: null,
      }
    }
  }
}

export function useThemeReducer(): [ThemeReducerState, ThemeDispatch] {
  return React.useReducer(themeReducer, initialThemeState)
}
