import {
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'

import { CSSinJSFormControl } from 'modules/code_editor/components/CSSinJSFormControl'

import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigAdvancedPanel = () => {
  const { pop, state, dispatch, label } = useThemeConfigNavigationContext()
  const { theme } = state

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <Stack>
        <FormControl>
          <FormLabel>
            <Trans>Font size</Trans>
          </FormLabel>
          <NumberInput
            defaultValue={theme.config?.fontSize}
            onChange={(_, val) => {
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: {
                  themeConfig: {
                    fontSize: Number.isNaN(val) ? undefined : val,
                  },
                },
              })
            }}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <CSSinJSFormControl
          label={<Trans>Content CSS</Trans>}
          initialValue={theme.config?.contentStyles}
          updateValue={(json) =>
            dispatch({
              type: 'THEME_UPDATE_THEME_CONFIG',
              data: { themeConfig: { contentStyles: json } },
            })
          }
        />
        <CSSinJSFormControl
          label={<Trans>Card effects</Trans>}
          initialValue={theme.config?.containerStyles}
          updateValue={(json) =>
            dispatch({
              type: 'THEME_UPDATE_THEME_CONFIG',
              data: { themeConfig: { containerStyles: json } },
            })
          }
          helperText={t`Applies to expanded cards.`}
        />
      </Stack>
    </ThemeConfigNavigationWrapper>
  )
}
