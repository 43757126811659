import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react'
import { Suspense, useEffect, useState } from 'react'

import { useDebounced, useEffectWhen } from 'utils/hooks'

import { CodeMirrorLazy } from './CodeMirrorLazy'

type JSON = Record<string, any>

const parseCSStoJSON = async (
  css: string,
  onSuccess: (json: JSON) => void,
  onError: (message: string) => void
) => {
  try {
    // https://github.com/postcss/postcss-js#compile-css-to-css-in-js
    // found via https://transform.tools/css-to-js
    const postCSS = (await import('postcss')).default
    const postCSSJS = (await import('postcss-js')).default
    const parsed = postCSS.parse(css)
    const json = postCSSJS.objectify(parsed)
    onSuccess(json)
  } catch (err) {
    onError(err.message)
  }
}

const parseJSONtoCSS = async (
  json: JSON,
  onSuccess: (css: string) => void,
  onError: (message: string) => void
) => {
  try {
    // https://github.com/postcss/postcss-js#compile-css-in-js-to-css
    // found via https://transform.tools/css-to-js
    const postCSS = (await import('postcss')).default
    const postCSSJS = (await import('postcss-js')).default
    postCSS()
      .process(json, { parser: postCSSJS })
      .then(({ css }) => {
        onSuccess(css)
      })
  } catch (err) {
    onError(err.message)
  }
}

type CSSinJSFormControlProps = {
  label: React.ReactNode
  helperText?: React.ReactNode
  initialValue?: JSON
  updateValue: (value: JSON) => void
}

export const CSSinJSFormControl = ({
  label,
  initialValue,
  updateValue,
  helperText,
}: CSSinJSFormControlProps) => {
  const [value, setValue] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [cssLang, setCssLang] = useState<any>()
  const debouncedParseCSStoJSON = useDebounced(parseCSStoJSON, 100)

  useEffect(() => {
    // Lazy load the lang-css and store and instance in state
    import('@codemirror/lang-css').then((module) => {
      setCssLang(module.css())
    })
  }, [])

  // On first load, parse the initialValue JSON into CSS
  useEffectWhen(
    () => {
      setError(null)
      if (!initialValue) return
      parseJSONtoCSS(initialValue, setValue, setError)
    },
    [initialValue],
    []
  )

  // On each update (debounced), parse the new CSS into JSON
  useEffectWhen(
    () => {
      setError(null)
      debouncedParseCSStoJSON(value, updateValue, setError)
    },
    [value, updateValue, debouncedParseCSStoJSON],
    [value]
  )

  if (!cssLang) return null

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{label}</FormLabel>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <Box border="1px solid" borderColor="gray.200" fontSize="md">
        <Suspense fallback={<span></span>}>
          <CodeMirrorLazy
            value={value}
            height="300px"
            extensions={[cssLang]}
            onChange={setValue}
          />
        </Suspense>
      </Box>
    </FormControl>
  )
}
