import {
  Box,
  ColorProps,
  HStack,
  Text,
  TypographyProps,
} from '@chakra-ui/react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type WarningLabelProps = {
  label: React.ReactNode
  description?: React.ReactNode
  labelColor?: ColorProps['color']
  fontFamily?: TypographyProps['fontFamily']
  fontStyle?: 'italic' | 'normal'
  fontWeight?: number
}
export const FontPickerWarningLabel = ({
  label,
  description,
  labelColor = 'black',
  fontFamily,
  fontStyle = 'normal',
  fontWeight = 400,
}: WarningLabelProps) => {
  const labelInner = (
    <HStack alignItems="baseline">
      <Box color="yellow.400">
        <FontAwesomeIcon icon={solid('warning')} />
      </Box>
      <Text
        textAlign="left"
        color={labelColor}
        fontWeight={fontWeight}
        fontSize="lg"
        fontFamily={fontFamily}
        fontStyle={fontStyle}
      >
        {label}
      </Text>
    </HStack>
  )
  if (!description) {
    return labelInner
  }
  return (
    <Box>
      {labelInner}
      <Text fontSize="xs" color="gray.500">
        {description}
      </Text>
    </Box>
  )
}
