import { ListItem, OrderedList } from '@chakra-ui/react'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'
import { useMemo } from 'react'

import { FeatureFlags, featureFlags } from 'modules/featureFlags'
import { NavigationStackComponent } from 'modules/navigation_stack/types'
import { Theme } from 'modules/theming/types'
import { useUserContext } from 'modules/user'

import { ThemeConfigAccentColorPanel } from './ThemeConfigAccentColorPanel'
import { ThemeConfigAccentImagesPanel } from './ThemeConfigAccentImagesPanel'
import { ThemeConfigAdvancedPanel } from './ThemeConfigAdvancedPanel'
import { ThemeConfigBackgroundPanel } from './ThemeConfigBackgroundPanel'
import { ThemeConfigCardStylesPanel } from './ThemeConfigCardStylesPanel'
import { ThemeConfigFontPanel } from './ThemeConfigFontPanel'
import { ThemeConfigLogoPanel } from './ThemeConfigLogoPanel'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationMenuButton } from './ThemeConfigNavigationMenuButton'

type ThemeNavigationMenuItem = {
  label: () => string
  icon:
    | FontAwesomeIconProps['icon']
    | ((theme: Theme) => FontAwesomeIconProps['icon'])
  Component: NavigationStackComponent
  featureFlag?: keyof FeatureFlags
  isForGammaOnly?: boolean
}

const themeNavigationMenuItems: ThemeNavigationMenuItem[] = [
  {
    label: () => t`Theme background`,
    icon: duotone('swatchbook'),
    Component: ThemeConfigBackgroundPanel,
  },
  {
    label: () => t`Card styles`,
    icon: duotone('rectangle'),
    Component: ThemeConfigCardStylesPanel,
  },
  {
    label: () => t`Accent colors`,
    icon: duotone('droplet'),
    Component: ThemeConfigAccentColorPanel,
  },
  {
    label: () => t`Accent images`,
    icon: duotone('images'),
    Component: ThemeConfigAccentImagesPanel,
  },
  {
    label: () => t`Fonts`,
    icon: duotone('font'),
    Component: ThemeConfigFontPanel,
  },
  {
    label: () => t`Logo`,
    icon: (theme: Theme) =>
      theme.logoUrl ? duotone('cat-space') : duotone('cat'),
    Component: ThemeConfigLogoPanel,
  },
  {
    label: () => t`Advanced`,
    icon: duotone('gear'),
    Component: ThemeConfigAdvancedPanel,
    isForGammaOnly: true,
  },
]
const checkIsComponentDisabled = (
  componentItem: ThemeNavigationMenuItem,
  isGammaOrgUser: boolean
) => {
  if (
    componentItem.featureFlag &&
    !featureFlags.get(componentItem.featureFlag)
  ) {
    return true
  }
  if (componentItem.isForGammaOnly && !isGammaOrgUser) {
    return true
  }
  return false
}

const adminOnlyMenuItemStyles = {
  outline: '3px dashed var(--chakra-colors-sunglow-500)',
  outlineOffset: '0',
}

export const ThemeConfigNavigationMenu = () => {
  const { push, state, setLabel } = useThemeConfigNavigationContext()
  const { theme } = state
  const { isGammaOrgUser } = useUserContext()

  const navItemsToRender = useMemo(
    () =>
      themeNavigationMenuItems.filter(
        (item) => !checkIsComponentDisabled(item, isGammaOrgUser)
      ),
    [isGammaOrgUser]
  )

  return (
    <OrderedList listStyleType="none" marginStart={0}>
      {navItemsToRender.map((menuItem, index) => {
        const { label, icon, Component } = menuItem
        const navLabel = `${index + 1}. ${label()}`
        return (
          <ListItem key={label()}>
            <ThemeConfigNavigationMenuButton
              icon={typeof icon === 'function' ? icon(theme) : icon}
              label={navLabel}
              onClick={() => {
                setLabel(navLabel)
                push(Component)
              }}
              {...(menuItem.isForGammaOnly &&
                isGammaOrgUser &&
                adminOnlyMenuItemStyles)}
            />
          </ListItem>
        )
      })}
    </OrderedList>
  )
}
