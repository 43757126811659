import { Alert, AlertIcon, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { UploadStatus } from 'modules/media/types/ImageUpload'
import {
  getThemeBackgroundOrDefault,
  isThemeDark,
} from 'modules/theming/utils/utils'
import { ThemeBackgroundPanel } from 'modules/tiptap_editor/components/panels/ThemeBackgroundPanel'
import {
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'

import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigBackgroundPanel = () => {
  const { dispatch, state, pop, label } = useThemeConfigNavigationContext()

  const background = getThemeBackgroundOrDefault(state.theme)
  const isDark = isThemeDark(state.theme)

  const updateThemeBackground = useCallback(
    (newBg?: BackgroundOptions) => {
      if (
        newBg?.type === BackgroundType.IMAGE &&
        newBg.image?.uploadStatus === UploadStatus.Uploading
      ) {
        dispatch({
          type: 'THEME_BACKGROUND_IMAGE_UPLOAD_STARTED',
          data: {
            background: newBg,
          },
        })
      } else if (
        newBg?.type === BackgroundType.IMAGE &&
        newBg.image?.uploadStatus === UploadStatus.Error
      ) {
        dispatch({
          type: 'THEME_BACKGROUND_IMAGE_UPLOAD_FAIL',
        })
      } else {
        dispatch({
          type: 'THEME_BACKGROUND_UPDATE_SUCCESS',
          data: {
            background: newBg,
          },
        })
      }
    },
    [dispatch]
  )

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <ThemeBackgroundPanel
        setBackground={updateThemeBackground}
        background={background}
        defaultMessage={
          <Alert fontSize="xs">
            <AlertIcon />
            <Text>
              <Trans>
                Choose an option above to set a default background for all pages
                using this theme.
              </Trans>
            </Text>
          </Alert>
        }
        isDark={isDark}
      />
    </ThemeConfigNavigationWrapper>
  )
}
