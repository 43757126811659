import { Trans } from '@lingui/macro'
export const getWeightsMap = () => ({
  100: <Trans>Thin</Trans>,
  200: <Trans>Extra Light</Trans>,
  300: <Trans>Light</Trans>,
  400: <Trans>Regular</Trans>,
  500: <Trans>Medium</Trans>,
  600: <Trans>Semi Bold</Trans>,
  700: <Trans>Bold</Trans>,
  800: <Trans>Extra Bold</Trans>,
  900: <Trans>Black</Trans>,
})
