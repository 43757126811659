import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Text,
} from '@chakra-ui/react'

import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'

type TextColorFormControlProps = {
  label: React.ReactNode
  helperText?: React.ReactNode
  value: string | null
  defaultValue: string
  updateValue: (value: string) => void
} & FormControlProps

export const TextColorFormControl = ({
  label,
  helperText,
  value,
  defaultValue,
  updateValue,
  ...controlProps
}: TextColorFormControlProps) => {
  return (
    <FormControl {...controlProps}>
      <FormLabel>
        <Text>{label}</Text>
      </FormLabel>
      <ColorPickerInput
        value={value}
        updateValue={updateValue}
        defaultValue={defaultValue}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
