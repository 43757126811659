import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useCallback } from 'react'

import { DEFAULT_ACCENT_COLOR } from 'modules/theming/constants'

import { ColorOrGradientPicker } from '../ColorOrGradientPicker'
import { ColorPalettePicker } from '../ColorPalettePicker'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigAccentColorPanel = () => {
  const { label, state, dispatch, pop } = useThemeConfigNavigationContext()
  const { theme } = state

  const updateAccentColor = useCallback(
    (color: string | null) =>
      dispatch({
        type: 'THEME_UPDATE_THEME_PROPS',
        data: { themeProps: { accentColor: color ?? undefined } },
      }),
    [dispatch]
  )

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <Stack spacing={2}>
        <FormControl data-testid="custom-theme-accent-color-picker">
          <FormLabel>
            <Trans>Primary accent color</Trans>
          </FormLabel>
          <FormHelperText mb={4}>
            <Trans>
              You can set an accent color or gradient that will be applied to
              elements like links, buttons, and blockquotes.
            </Trans>
          </FormHelperText>
          <ColorOrGradientPicker
            color={theme.accentColor}
            defaultColor={DEFAULT_ACCENT_COLOR}
            updateColor={updateAccentColor}
            gradient={theme.config.accentGradient}
            updateGradient={(accentGradient) =>
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: { themeConfig: { accentGradient } },
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Trans>Secondary accent colors</Trans>
          </FormLabel>
          <FormHelperText mb={2}>
            <Trans>
              Additional accent colors will be applied to decorative elements in
              your theme such as table dividers and timelines. If you don’t add
              any secondary accent colors, the primary accent color or gradient
              will be applied.
            </Trans>
          </FormHelperText>
          <ColorPalettePicker
            value={theme.config?.secondaryAccentColors || []}
            updateValue={(secondaryAccentColors) =>
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: { themeConfig: { secondaryAccentColors } },
              })
            }
            maxColors={4}
          />
        </FormControl>
        <FormControl>
          <FormLabel mt={4}>
            <Trans>Readability</Trans>
          </FormLabel>
          {/* Add a checkbox to toggle readability settings */}
          <Checkbox
            isChecked={!theme.config?.disableReadabilityAdjustment}
            onChange={() =>
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: {
                  themeConfig: {
                    disableReadabilityAdjustment:
                      !theme.config?.disableReadabilityAdjustment,
                  },
                },
              })
            }
          >
            <Trans>Adjust colors to be readable</Trans>
          </Checkbox>
          <FormHelperText mb={2}>
            <Trans>
              By default, Gamma adjusts your colors to comply with web
              accessibility guidelines. You can turn this off to use your colors
              exactly as they are.
            </Trans>
          </FormHelperText>
        </FormControl>
      </Stack>
    </ThemeConfigNavigationWrapper>
  )
}
