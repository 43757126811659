import { FormControl, FormHelperText, FormLabel, Stack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { memo } from 'react'

import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'

import { ThemeBaseFormControl } from '../ThemeBaseFormControl'
import { useThemeConfigNavigationContext } from './ThemeConfigNavigationContext'
import { ThemeConfigNavigationWrapper } from './ThemeConfigNavigationWrapper'

export const ThemeConfigCardStylesPanel = memo(() => {
  const { state, pop, dispatch, label } = useThemeConfigNavigationContext()
  const { theme } = state

  const cardBackgroundColor =
    theme.config.cardBackground?.type === BackgroundType.COLOR
      ? theme.config.cardBackground.color
      : null

  return (
    <ThemeConfigNavigationWrapper onBackClick={pop} label={label}>
      <Stack>
        <FormControl data-testid="custom-theme-card-color-picker">
          <FormLabel>
            <Trans>Card color</Trans>
          </FormLabel>
          <FormHelperText mb={4}>
            <Trans>
              The default background color of the card itself. Text colors will
              adjust automatically to stand out against this background.
            </Trans>
          </FormHelperText>
          {/* Right now, we only allow colors for the card background. In the future we may allow other types, so we'll model this as BackgroundOptions but only set color ones for now. */}
          <ColorPickerInput
            value={cardBackgroundColor?.hex || null}
            updateValue={(color) =>
              dispatch({
                type: 'THEME_UPDATE_THEME_CONFIG',
                data: {
                  themeConfig: {
                    cardBackground: color
                      ? {
                          type: BackgroundType.COLOR,
                          color: {
                            hex: color,
                          },
                        }
                      : undefined,
                  },
                },
              })
            }
            defaultValue="#FFFFFF" // todo: inherit from base theme
          />
        </FormControl>
        <ThemeBaseFormControl theme={theme} dispatch={dispatch} />
      </Stack>
    </ThemeConfigNavigationWrapper>
  )
})

ThemeConfigCardStylesPanel.displayName = 'ThemeConfigCardStylesPanel'
